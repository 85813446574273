import React from "react"
import { Link } from "gatsby"

import BlogLayout from "../components/blog-layout"
import SEO from "../components/seo"

import "./typography.css"

const TagPostsTemplate = ({ data, pageContext, location }) => {
    const tag = pageContext.tag;
    const posts = pageContext.posts;
    const siteTitle = data.site.siteMetadata.title;
    console.warn(posts);
    return (
        <BlogLayout location={location} title={siteTitle}>
            <SEO title="Posts" />
            <main className="main-contents">
                <h1>Tag "{tag}"</h1>
                {posts.map(({ node }) => {
                    const title = node.frontmatter.title || node.fields.slug
                    return (
                    <article key={node.fields.slug} style={{
                        borderBottom: "dotted 1px",
                        position: "relative",
                        padding: "1rem 0"
                    }}>
                        <h3 style={{
                            margin: 0
                        }}>
                            <Link style={{ boxShadow: `none` }} to={`/posts${node.fields.slug}`}>
                            {title}
                            </Link>
                        </h3>
                        <small>{node.frontmatter.date}時頃</small>
                    </article>
                    )
                })}
            </main>
        </BlogLayout>
    );
}

export default TagPostsTemplate
export const pageQuery = graphql`
query {
    site {
        siteMetadata {
            title
            origin
        }
    }
}`